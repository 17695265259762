import Styles from './Template6.scss';

import Template from './Template6.hbs';
import Item from 'views/components/taskGroups/tasks/template6/item/Item';
import 'jquery-ui/ui/widgets/sortable';

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'saveAnswer',
            'showAnswer',
            'getCorrectAnswer',
            'lockAnswer',
            'unlockAnswer'
        );

        this.taskView = options.task_view;

        this.renderTask()
    },

    /**
     * renderTask
     *
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     *
     */
    renderTask() {

        this.setElement(Template({
            Styles
        }));

        this.items = this.model.get('task_info_json').options;
        this.correctItems = this.model.get('task_info_json').correct_answer;
        this.answeredItems = this.taskView.response.get('json_answer');
        this.partialGrading = this.model.get('task_info_json').partialGrading ?? false;

        if (APPLICATION === 'author') {
            return
        }

        if (!_.isEmpty(this.answeredItems)) {
            this.items = this.answeredItems;
        }

        _.each(this.items, (item, index) => {
            this.addChildView(new Item({
                label: item,
                index,
            }), '.js-sortable')
        })

        this.$('.js-sortable').sortable({
            containment: this.$el,
            cursor: 'ns-resize',
            items: '.js-item',

            // NOTE changed this for desktop and mobile on 20-03-2018.
            // This fix will make it easier for draggable elements with more than 1 line of text to
            // switch positions with the top element. For more info:
            // https://api.jqueryui.com/sortable/#option-tolerance
            tolerance: 'pointer',
            handle: ISMOBILE ? '.js-handle-holder' : this.$el,
            start: this.onStart,
            stop: this.onStop,
            over: this.onHover,
            opacity: 1,
            update: this.saveAnswer,
            revert: 250,
            scrollSpeed: 10
        });

    },

    /**
         * onStart
         *
         * Triggered when the sorting starts
         *
         * @param  {Object} e  event object
         * @param  {Object} ui - jQuery ui object
         */
    onStart(e, ui) {
        ui.item.addClass(Styles['is-sorting']);
    },

    /**
         * onStop
         *
         * Triggered when the sorting stops
         *
         * @param  {Object} e  event object
         * @param  {Object} ui - jQuery ui object
         */
    onStop(e, ui) {
        ui.item.removeClass(Styles['is-sorting']);
    },

    /**
         * onHover
         *
         * Triggered when the element that is being dragged is inside a dropping position
         *
         * @param  {Object} e  event object
         * @param  {Object} ui - jQuery ui object
         */
    onHover(e, ui) {

        ui.placeholder.addClass(Styles.dropzone).css({
            visibility: 'visible'
        });

    },

    saveAnswer() {
        var ordering = this.$('.js-sortable').sortable('toArray');
        var savedAnswer = [];
        _.each(ordering, (index) => {
            savedAnswer.push(this.items[index]);
        })
        this.taskView.saveResponse(savedAnswer);
    },

    showAnswer() {
        const ordering = this.$('.js-sortable').sortable('toArray');
        const currentGivenAnswer = ordering.map(index => this.items[index]);
        const lessThanFourItems = this.correctItems.length <= 3;

        if (lessThanFourItems || !this.partialGrading) {
            this.displayExactAnswer();
            return;
        }

        // The `findSingleMistake()` function returns the first index when everything is correct,
        // this is because there is a correct subSequence when removing 0 (because there always is).
        // We prevent this mistake by checking on fullscore above.
        const incorrectIndex = this.findSingleMistakeIndex(currentGivenAnswer, this.correctItems);
        if (incorrectIndex !== -1) {
            this.displaySingleMistake(incorrectIndex);
            return;
        }

        this.displayExactAnswer();
    },

    displayExactAnswer() {
        this.$('.js-item').each((index, itemElement) => {
            const $item = $(itemElement);
            $item.find('.js-sequence')
                .text(this.correctItems.indexOf(this.items[itemElement.id]) + 1)
                .css('display', 'flex');
            $item.find('.js-handle').hide();

            const isCorrect = _.isEqual(this.items[itemElement.id], this.correctItems[index]);
            $item.addClass(isCorrect ? Styles['item--correct'] : Styles['item--incorrect']);
        });
    },

    findSingleMistakeIndex(currentGivenAnswer, correctAnswer) {
        if (JSON.stringify(currentGivenAnswer) === JSON.stringify(correctAnswer)) {
            return -1;
        }

        return currentGivenAnswer.findIndex((_, i) => {
            const modifiedItems = [...currentGivenAnswer];
            modifiedItems.splice(i, 1);
            return this.isSubsequence(modifiedItems, correctAnswer);
        });
    },

    displaySingleMistake(incorrectIndex) {
        this.$('.js-item').each((index, itemElement) => {
            $(itemElement).addClass(index === incorrectIndex ? Styles['item--incorrect'] : Styles['item--correct']);
        });
    },

    isSubsequence(subarray, array) {
        // This function iterates through the main array, and for each element that matches
        // the current element of the subarray (starting from the first element of the subarray),
        // it moves to the next element in the subarray. If all elements of the subarray are
        // found in the same order in the main array, the function returns true, indicating
        // that the subarray is a subsequence of the array. If not, it returns false.

        let subIndex = 0;

        for (const item of array) {
            if (subIndex < subarray.length && subarray[subIndex] === item) {
                subIndex++;
            }
            if (subIndex === subarray.length) {
                return true;
            }
        }

        return false;
    },

    showAuthorAnswer() {
        _.each(this.model.get('task_info_json').correct_answer, (item, index) => {
            const itemView = this.addChildView(new Item({
                label: item,
                index,
            }), '.js-sortable')
            itemView.$('.js-sequence').text(index + 1).css('display', 'flex')
            itemView.$('.js-handle').hide()
        })
    },

    /**
         * hideAnswer
         *
         * Hide the correct answer to the task.
         */
    hideAnswer() {
        this.$('.js-item').removeClass(Styles['item--correct'] + ' ' + Styles['item--incorrect']);
        this.$('.js-item .js-sequence').empty().hide();
        this.$('.js-item .js-handle').show();
    },

    /**
         * getCorrectAnswer
         *
         * Generate summary of the correct answer to use above the list of student answers.
         *
         * @return {string} HTML blob to be inserted above the list of student answers.
         */
    getCorrectAnswer() {
        var answerString = '';
        _.each(this.model.get('task_info_json').correct_answer, (answer, key) => {
            answerString += '<span class="is-boxed">' + (key + 1) + '. ' + answer + '</span> ';
        })
        return answerString;
    },

    /**
     * getStudentAnswer
     *
     * Generate summary of the answer student has given for use in the list of student answers.
     *
     * @param  {Object} responseModel Student response data
     * @return {string}               HTML blob to be used for the student answer view.
     */
    getStudentAnswer(responseModel) {
        const givenAnswer = responseModel.get('json_answer');
        const correctAnswer = this.model.get('task_info_json').correct_answer;
        const partialGrading = this.model.get('task_info_json').partialGrading ?? false;
        const lessThanFourItems = correctAnswer.length <= 3;

        if (!givenAnswer) {
            return '';
        }

        if (lessThanFourItems || !partialGrading) {
            return this.formatStudentAnswerString(givenAnswer, correctAnswer);
        }

        const incorrectIndex = this.findSingleMistakeIndex(givenAnswer, correctAnswer);
        return this.formatStudentAnswerString(givenAnswer, correctAnswer, incorrectIndex);
    },

    formatStudentAnswerString(givenAnswer, correctAnswer, incorrectIndex = -1) {
        let answerString = '';
        if (incorrectIndex !== -1) {
            // When partialGrading is on and we found only 1 incorrect index, we need to make this visible.
            _.each(givenAnswer, (answer, key) => {
                if (key !== incorrectIndex) {
                    answerString += '<span class="is-boxed is-correct">' + (key + 1) + '. ' + answer + '</span> ';
                } else {
                    answerString += '<span class="is-boxed is-incorrect">' + (key + 1) + '. ' + answer + '</span> ';
                }
            });
        } else {
            _.each(givenAnswer, (answer, key) => {
                if (correctAnswer[key] === answer) {
                    answerString += '<span class="is-boxed is-correct">' + (key + 1) + '. ' + answer + '</span> ';
                } else {
                    answerString += '<span class="is-boxed is-incorrect">' + (key + 1) + '. ' + answer + '</span> ';
                }
            });
        }

        return answerString;
    },

    /**
         * lockAnswer
         *
         * Triggered when the lock-answers event is sent from a Presentation view.
         * It will make sure students can't change their answer. This is typically
         * done when the taskState inside the presentation is 1 or 2, meaning the
         * teacher is showing the student's answers.
         */
    lockAnswer() {
        _.invoke(this.getChildViewsOfInstance(Item), 'lock')
        this.$('.js-sortable').sortable('instance')?.disable()
    },

    /**
         * unlockAnswer
         *
         * Triggered when the unlock-answers event is sent from a Presentation view.
         * It will make sure students chan fill an answer again.
         */
    unlockAnswer() {
        _.invoke(this.getChildViewsOfInstance(Item), 'unlock')
        this.$('.js-sortable').sortable('instance')?.enable()
    }

});
