import Styles from './SetVisibility.scss';

import Template from './SetVisibility.hbs';
import ContentLabel from 'views/components/contentLabel/ContentLabel.svelte';
import RadioButton from 'views/components/radioButton/RadioButton.svelte';

export default class SetVisibility extends BaseView {

    initialize({ activitiesCollection }) {

        _.bindAll(
            this,
            'saveChoice',
            'saveActivityVisibility',
            'saveAnswerVisibility',
            'updateVisibility',
            'updateAnswersOnCompletion',
            'showStatus'
        );

        this.setElement(Template({
            Styles
        }));

        // Activities collection is present when called from sections/show. It is needed to
        // update the UI after saving the new visibility.
        this.activitiesCollection = activitiesCollection || new Backbone.Collection()
        this.isChapter = this.model.constructor.type === 'chapter';
        this.isGroup = this.model.constructor.type === 'group';
        this.listWithUserIds = this.model.getGroupModel().students.pluck('id');

        this.addSvelteChildView('.js-location', ContentLabel, {
            model: this.model.isRootLayer() ? this.model.getGroupModel() : this.model,
            labelPrefix: window.i18n.gettext('For the learning materials in')
        })

        this.options = [{
            label: window.i18n.gettext('Make activities visible'),
            value: 'activities:visible',
        }, {
            label: window.i18n.gettext('Make activities invisible'),
            value: 'activities:invisible',
        }, {
            label: window.i18n.gettext('Make answers visible'),
            value: 'answers:visible',
        }, {
            label: window.i18n.gettext('Make answers invisible'),
            value: 'answers:invisible',
        }, {
            label: window.i18n.gettext('Turn on answers visible at 100 percent complete'),
            value: 'answers:onAt100',
        }, {
            label: window.i18n.gettext('Turn off answers visible at 100 percent complete'),
            value: 'answers:offAt100',
        }]

        for (const option of this.options) {
            this.addSvelteChildView(
                this.el.querySelector('form'),
                RadioButton,
                {
                    label: option.label,
                    value: option.value,
                    parentGroup: 'selection'
                }
            )
        }

    }

    // Add buttons to the footer of the modal
    addButtons() {
        Backbone.View.Components.modal.addButtons({
            [window.i18n.gettext('Cancel')]: {
                callback: Backbone.View.Components.modal.close,
                theme: 'secondary',
            },
            [window.i18n.gettext('Apply')]: {
                callback: this.saveChoice,
                keyCode: 13,
            }
        })
    }

    // Get the selection and call the relevant function to update the models
    saveChoice() {
        const selection = this.el.querySelector('input[name=selection]:checked')

        if (!selection) {
            Backbone.View.layout.openStatus(window.i18n.gettext('Choose an option first.'), 'warning')
            return
        }

        const [level, value] = selection.value.split(':')
        level === 'activities' ? this.saveActivityVisibility(value) : this.saveAnswerVisibility(value);

        Backbone.View.Components.modal.close();
    }

    // Call the backend to set the visibility of activities
    saveActivityVisibility(visibility) {
        const activitiesKey = 'activity_visible';
        if (visibility === 'visible' || visibility === 'invisible') {
            const isOn = visibility === 'visible' ? 1 : 0;
            const list = isOn ? this.listWithUserIds : [];

            if (this.isGroup) {
                $.post('/groups/update_visibility.json', {
                    group_id: this.model.id,
                    key: activitiesKey,
                    value: isOn
                }, (response) => {
                    const activityCount = response.updated;
                    this.showStatus('status', activitiesKey, isOn, activityCount);
                }).fail(() => {
                    this.showStatus('error');
                });
            } else if (this.isChapter) {
                $.post('/chapters/update_visibility.json', {
                    chapter_id: this.model.id,
                    key: activitiesKey,
                    value: isOn
                }, (response) => {
                    const activityCount = response.updated;
                    this.showStatus('status', activitiesKey, isOn, activityCount);
                }).fail(() => {
                    this.showStatus('error');
                });
            } else {
                $.post('/sections/update_visibility.json', {
                    section_id: this.model.id,
                    key: activitiesKey,
                    value: isOn
                }, (response) => {
                    const activityCount = response.updated;
                    this.updateVisibility(activitiesKey, list);
                    this.showStatus('status', activitiesKey, isOn, activityCount);
                }).fail(() => {
                    this.showStatus('error');
                });
            }
        }
    }

    // Call the backend to set the visibility of answers
    saveAnswerVisibility(visibility) {
        if (visibility === 'visible' || visibility === 'invisible') {
            const answersKey = 'answers_visible';
            const isOn = visibility === 'visible' ? 1 : 0;
            const list = isOn ? this.listWithUserIds : [];

            if (this.isGroup) {
                $.post('/groups/update_visibility.json', {
                    group_id: this.model.id,
                    key: answersKey,
                    value: isOn
                }, (response) => {
                    const activityCount = response.updated;
                    this.showStatus('status', answersKey, isOn, activityCount);
                }).fail(() => {
                    this.showStatus('error');
                });
            } else if (this.isChapter) {
                $.post('/chapters/update_visibility.json', {
                    chapter_id: this.model.id,
                    key: answersKey,
                    value: isOn
                }, (response) => {
                    const activityCount = response.updated;
                    this.showStatus('status', answersKey, isOn, activityCount);
                }).fail(() => {
                    this.showStatus('error');
                });
            } else {
                $.post('/sections/update_visibility.json', {
                    section_id: this.model.id,
                    key: answersKey,
                    value: isOn
                }, (response) => {
                    const activityCount = response.updated;
                    this.updateVisibility(answersKey, list);
                    this.showStatus('status', answersKey, isOn, activityCount);
                }).fail(() => {
                    this.showStatus('error');
                });
            }
        } else {
            const isOn = visibility === 'onAt100' ? 1 : 0;
            const answersKey = 'answers_visible_on_completion';
            if (this.isGroup) {
                $.post('/groups/update_visibility.json', {
                    group_id: this.model.id,
                    key: answersKey,
                    value: isOn
                }, (response) => {
                    const activityCount = response.updated;
                    this.showStatus('status', answersKey, isOn, activityCount);
                }).fail(() => {
                    this.showStatus('error');
                });
            } else if (this.isChapter) {
                $.post('/chapters/update_visibility.json', {
                    chapter_id: this.model.id,
                    key: answersKey,
                    value: isOn
                }, (response) => {
                    const activityCount = response.updated;
                    this.showStatus('status', answersKey, isOn, activityCount);
                }).fail(() => {
                    this.showStatus('error');
                });
            } else {
                $.post('/sections/update_visibility.json', {
                    section_id: this.model.id,
                    key: answersKey,
                    value: isOn
                }, (response) => {
                    const activityCount = response.updated;
                    this.updateAnswersOnCompletion(answersKey, isOn);
                    this.showStatus('status', answersKey, isOn, activityCount);
                }).fail(() => {
                    this.showStatus('error');
                });

            }
        }
    }

    // update UI with new visibility settings
    updateVisibility(key, list) {
        this.activitiesCollection.each((item) => {
            item.set(key, list);
        });
    }

    // update UI with new answers on completion settings
    updateAnswersOnCompletion(key, value) {
        this.activitiesCollection.each((item) => {
            if (item.get('type') === 'linear') {
                item.setMetadata(key, value, false)
            }
        })
    }

    /**
         * showStatus - show the status message with information about the setting update
         *
         * @param  {string} status error/success of the update
         * @param  {string} key    which setting was changed
         * @param  {boolean} isOn   was the setting turned on or off
         * @param  {number} activityCount   was the setting turned on or off
         */
    showStatus(status, key, isOn, activityCount) {
        if (status === 'error') {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Something went wrong'),
                'error'
            )
            return
        }

        if (activityCount === 0) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('There are no activities to update.'),
                'warning'
            )
            return
        }

        let statusMessage
        switch (key) {
            case 'activity_visible':
                statusMessage = isOn ?
                    window.i18n.sprintf(
                        window.i18n.ngettext(
                            '%s activity is now visibile.',
                            '%s activities are now visibile.',
                            activityCount),
                        activityCount
                    ) :
                    window.i18n.sprintf(
                        window.i18n.ngettext(
                            '%s activity is now invisibile.',
                            '%s activities are now invisibile.',
                            activityCount),
                        activityCount
                    );
                break;
            case 'answers_visible':
                statusMessage = isOn ?
                    window.i18n.sprintf(
                        window.i18n.ngettext(
                            'The answers of %s activity are now visible.',
                            'The answers of %s activities are now visible.',
                            activityCount),
                        activityCount
                    ) :
                    window.i18n.sprintf(
                        window.i18n.ngettext(
                            'The answers of %s activity are now invisible.',
                            'The answers of %s activities are now invisible.',
                            activityCount),
                        activityCount
                    );
                break;
            case 'answers_visible_on_completion':
                statusMessage = isOn ?
                    window.i18n.sprintf(
                        window.i18n.ngettext(
                            'The answers of %s activity will become visible when they are 100 percent completed.',
                            'The answers of %s activities will become visible when they are 100 percent completed.',
                            activityCount),
                        activityCount
                    ) :
                    window.i18n.sprintf(
                        window.i18n.ngettext(
                            'The answers of %s activity will not become visible when they are 100 percent completed.',
                            'The answers of %s activities will not become visible when they are 100 percent completed.',
                            activityCount),
                        activityCount
                    );
                break;
        }

        Backbone.View.layout.openStatus(
            statusMessage,
            'success'
        );
    }
}
