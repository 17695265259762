import ElementModel from 'models/ElementModel'
import Util from 'util/util'
export default class TaskModel extends ElementModel {

    get hasValidTemplateId() {
        return TaskModel.validTemplateIds.includes(this.get('template_id'))
    }

    get isIncompatibleWithSEB() {
        return TaskModel.SEBIncompatibleTemplates.includes(this.get('template_id'))
    }

    get defaults() {
        return {
            element_type: 'task',
            Subject: {subject: undefined, id: 0},
            QuestionType: {question_type: '...', id: 0},
            introduction: ''
        }
    }

    static get validTemplateIds() {
        return [
            1, 2, 6, 11, 16, 21, 22, 23, 25, 26, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39
        ]
    }

    static get canBeGradedAutomatically() {
        return [
            1, 2, 6, 11, 16, 22, 25, 26, 28, 29, 30, 31, 32, 33, 34, 35, 39,
        ]
    }

    static get canBeGradedByStudent() {
        return [
            2, 21, 22, 23, 25, 26, 31, 37, 38
        ]
    }

    static get SEBIncompatibleTemplates() {
        return [
            // student file upload
            21,
            // record audio
            23,
        ]
    }

    preinitialize() {
        this.constructor.type = 'task'
        this.constructor.pluralType = 'tasks'
    }

    url() {
        if (this.id) {
            return '/tasks/backbone_backend/' + this.id + '.json'
        }
        return '/tasks/backbone_backend.json'
    }

    initEmptyState() {
        const initialState = this.getInitialStateData()
        this.set({
            task_info_json: initialState,
            grading_mode: this.getDefaultGradingMode(),
        })
    }

    getInitialStateData() {
        return TaskModel.getInitialStateData(this.get('template_id'))
    }

    static getInitialStateData(templateId) {
        switch (templateId) {
            case 1:
                return {
                    answer: [],
                    correct: [],
                    inputType: 'text',
                    shuffle: true
                }
            case 2: return {answer: ''}
            case 6: return {answer: []}
            case 11: return {answer: {a: [], b: []}, distractors: []}
            case 16: return {sentence: [], options: [], underlined: []}
            case 21: return {description: '', allowedTypes: []}
            case 22:
                return {
                    // Create table of 4 rows and 5 columns with no gap cells or text content.
                    // A "table" is a flat list of objects representing a cell. Each cell has a
                    // unique combination of columnIndex and rowIndex (zero-indexed) denoting its
                    // position in the table.
                    gridCells: _.flatten(_.times(4, (rowIndex) => {
                        return _.times(5, (columnIndex) => {
                            return {
                                columnIndex,
                                rowIndex,
                                cellText: '',
                                isGapCell: false
                            }
                        })
                    }))
                }
            case 25: return {answers: [], answerType: 'dragText'}
            case 26: return {molSketchData: {}, options: {}}
            case 28: return {categories: []}
            case 29:
            case 30:
            case 31: return {text: []}
            case 32: return {text: [], distractors: []}
            case 34: return {items: []}
            case 38: return {program: '', level: 1}
            case 39:
                return {
                    // Create table of 4 rows and 5 columns with no gap cells or text content.
                    // A "table" is a flat list of objects representing a cell. Each cell has a
                    // unique combination of columnIndex and rowIndex (zero-indexed) denoting its
                    // position in the table.
                    // If the cell is not on the left-most and top-most edge of the table, make
                    // that cell a gap cell with the radio input type, meaning that only one gap
                    // cell in that same row can be selected at one time. In the case of Template39
                    // cellText is a boolean value, true meaning that the input is checked. For the
                    // initial state, set the inputs on column index 1 as checked.
                    gridCells: _.flatten(_.times(4, (rowIndex) => {
                        return _.times(5, (columnIndex) => {
                            const cell = {
                                columnIndex,
                                rowIndex,
                                cellText: '',
                                isGapCell: false
                            }
                            if (rowIndex > 0 && columnIndex > 0) {
                                cell.isGapCell = true
                                cell.gapType = 'radio'
                                cell.cellText = columnIndex === 1
                            }
                            return cell
                        })
                    }))
                }
            default:
                return {}
        }
    }

    getShortText() {
        // Check if there is an introduction first before trimming it, otherwise return an empty string.
        return Util.stripTags(this.get('introduction') && this.get('introduction').trim()) || '';
    }

    getElementName() {
        return TaskModel.getElementName(this.get('template_id'))
    }

    static getElementName(templateId) {
        switch (templateId) {
            case 1: return window.i18n.gettext('Multiple choice');
            case 2: return window.i18n.gettext('Open question');
            case 6: return window.i18n.gettext('Ordering');
            case 11: return window.i18n.gettext('Combinations');
            case 16: return window.i18n.gettext('Parsing');
            case 21: return window.i18n.gettext('File upload');
            case 22: return window.i18n.gettext('Gap text table');
            case 23: return window.i18n.gettext('Record sound');
            case 25: return window.i18n.gettext('Hotspot on image');
            case 26: return window.i18n.gettext('Structural formula')
            case 28: return window.i18n.gettext('Grouping');
            case 29: return window.i18n.gettext('Word selection');
            case 30: return window.i18n.gettext('Gap text choice');
            case 31: return window.i18n.gettext('Gap text open');
            case 32: return window.i18n.gettext('Gap text drag');
            case 33: return window.i18n.gettext('Text selection');
            case 34: return window.i18n.gettext('Text order');
            case 35: return window.i18n.gettext('AlgebraKiT')
            case 36: return window.i18n.gettext('Check off');
            case 37: return window.i18n.gettext('Drawing');
            case 38: return window.i18n.gettext('Programming with Hedy');
            case 39: return window.i18n.gettext('Multiple choice table');
        }
    }

    getElementIcon() {
        return TaskModel.getElementIcon(this.get('template_id'))
    }

    static getElementIcon(templateId) {
        switch (templateId) {
            case 1: return 'task-1';
            case 2: return 'task-2';
            case 6: return 'task-6';
            case 11: return 'task-11';
            case 16: return 'task-16';
            case 21: return 'task-21';
            case 22: return 'task-22';
            case 23: return 'microphone';
            case 25: return 'task-25';
            case 26: return 'task-26'
            case 28: return 'task-28';
            case 29: return 'task-29';
            case 30: return 'task-30';
            case 31: return 'task-31';
            case 32: return 'task-32';
            case 33: return 'task-33';
            case 34: return 'task-34';
            case 35: return 'task-35'
            case 36: return 'task-36';
            case 37: return 'draw';
            case 38: return 'code';
            case 39: return 'task-39';
        }
    }

    getDefaultGradingMode() {
        return TaskModel.getDefaultGradingMode(this.get('template_id'))
    }

    static getDefaultGradingMode(templateId) {
        if (templateId === 2) {
            return 'student'
        }
        if (TaskModel.canBeGradedAutomatically.includes(templateId)) {
            return 'auto'
        }
        if (TaskModel.canBeGradedByStudent.includes(templateId)) {
            return 'student'
        }
        return 'none'
    }

    async save_element(attributes) {

        const explanation = this.metaData.explanationEditor && this.metaData.explanationEditor.getContent()

        // Extend the attributes with task specific attributes
        Object.assign(attributes, {
            template_id: this.get('template_id'),
            has_model_answer: attributes.has_model_answer ? 1 : 0,
            grading_mode: this.metaData.getNewGradingMode(),
            explanation,
            question_type_id: (
                this.metaData.questionTypeInput && this.metaData.questionTypeInput.getValue().item?.value
            ) || 0,
            framework_id: (
                this.metaData.frameworkInput && this.metaData.frameworkInput.getValue().item?.value
            ) || 0,
            subject_id: (
                this.metaData.subjectPicker && this.metaData.subjectPicker.selectedSubjectId
            ) || 0,
        })

        // If the to be save task has no model answer
        if (!attributes.has_model_answer) {

            // And grading mode is auto
            if (
                attributes.grading_mode === 'auto' || (

                    attributes.grading_mode === 'student' &&

                    // If there is an explanation editor
                    this.metaData.explanationEditor &&

                    // And there is also no explanation set
                    _.size(explanation) === 0
                )
            ) {

                // Tell user we've changed their grading mode, because
                // we can't automatically grade a task without a model
                // answer
                Backbone.View.layout.openStatus(
                    window.i18n.gettext(
                        'No model answer was provided.'
                    ),
                    'info'
                )
            }
        }

        if (this.metaData.partialGradingCheckbox) {
            const isPartialGradingEnabled = this.metaData.partialGradingCheckbox.getState()

            // For template 6 and 16 there is a difference in json-structure. This is because the backend
            // is returning the `task_info_json.options` key as the answer options for the student. Because of this,
            // we cannot use this key for extra settings and we are setting this key directly on the `task_info_json`
            if ([6, 16].includes(this.get('template_id'))) {
                attributes.task_info_json.partialGrading = isPartialGradingEnabled
            } else {
                attributes.task_info_json.options = Object.assign(
                    attributes.task_info_json.options || {},
                    {partialGrading: isPartialGradingEnabled}
                )
            }
        }

        await this.save(attributes, {
            patch: true,
            wait: true,
        })
    }

}
