import Styles from './Template2.scss';

import Template from './Template2.hbs';
import Wysiwyg from 'views/components/wysiwyg/Wysiwyg';
import DefaultImageElement from 'views/components/taskGroups/sources/source12/templates/elements/image.hbs';
import AnswerExplanation from 'views/components/taskGroups/tasks/answerExplanation/AnswerExplanation';
import GradingTemplate from 'views/components/taskGroups/tasks/template2/Grading.hbs'
import StarRater from 'views/components/starRater/StarRater'
import renderSpecialElements from 'util/RenderSpecialElements';
import Util from 'util/util';
import AnswerDiff from './AnswerDiff/AnswerDiff';
import AuthorPreviewTemplate from './AuthorPreview.hbs'
import CopyToPortfolioView from 'views/pages/activities/copyToPortfolioView/CopyToPortfolioView'
import Textarea from 'views/components/textarea/Textarea'
import WordCounter from 'views/components/taskGroups/tasks/template2/wordCounter/WordCounter';
import Template2AnswerTemplate from './Template2Answer.hbs'

export default BaseView.extend({

    events: {
        'change textarea': 'textareaChanged',
        'input textarea': 'onTextareaUpdate',
        'click .js-textarea-wrapper': 'textareaWrapperClick'
    },

    // Make a mapping for element templates
    specialElementsConvertList: {

        // Convertion for HTML element: 'img'
        img: {

            // Default options
            global: {

                // Set template for the img tag
                template: DefaultImageElement,
            }
        }
    },

    initialize(options) {

        _.bindAll(this,
            'textareaChanged',
            'showAnswer',
            'hideAnswer',
            'onTextareaUpdate',
            'lockAnswer',
            'unlockAnswer',
            'sendPresentationAnswer',
            'isCorrectAnswer',
            'getInputValue',
            'addResponseAccuracyStyling',
            'checkAnswer'
        );

        this.task_view = options.task_view;

        this.isPresentation = options.isPresentation;

        this.show_answers = options.show_answers

        this.response = this.task_view.response

        this.renderTask()

        this.renderPortfolioPlacer()
    },

    /**
     * renderTask
     *
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     *
     */
    renderTask() {
        this.setElement(Template({
            Styles
        }));

        if (APPLICATION === 'author') {
            return
        }

        // No rich text editors for presentation mode
        if (!this.isPresentation) {

            if (
                (
                    // Check if the input should be formatted
                    this.model.get('task_info_json').formatted === true

                // OR
                ) ||
                (
                    // Check if the user needs equation tools
                    this.model.get('task_info_json').equationTools === true
                )
            ) {

                let wysiwygButtonsPreset = null

                // When the author set the answer to a formatted setting
                if (this.model.get('task_info_json').formatted === true) {
                    wysiwygButtonsPreset = 'taskGroupsTemplate2Formatted'
                }

                // When the author set the answer to enable equation tools
                if (this.model.get('task_info_json').equationTools === true) {
                    if (wysiwygButtonsPreset) {
                        wysiwygButtonsPreset = 'taskGroupsTemplate2All'
                    } else {
                        wysiwygButtonsPreset = 'taskGroupsTemplate2Equations'
                    }
                }

                // Create a wysiwyg view
                this.advancedInput = this.addChildView(new Wysiwyg({
                    content: this.task_view.response.get('json_answer'),
                    autoFocus: false,
                    buttonsPreset: wysiwygButtonsPreset,
                    noAutoCorrect: true
                }), this.$el, 'prepend')

                // Once the editor has loaded, listen to both input and change events
                // Input: typing or deleting text
                // Change: formatting, pasting, blurring the editor
                this.advancedInput.once('editorLoaded', () => {
                    // When the author allows the display of a word counter
                    if (this.model.get('task_info_json').word_counter === true) {
                        this.wordCounter = this.addChildView(new WordCounter(), '.js-word-counter-container')
                        this.updateWordCounter()
                    }
                    this.advancedInput.on('change', this.onTextareaUpdate)
                })
            }
        }

        if (!this.advancedInput) {
            const isCompact = this.model.get('task_info_json').compact
            if (isCompact) {
                this.el.classList.add(Styles['is-compact'])
            }

            this.addChildView(new Textarea({
                value: this.task_view.response.get('json_answer'),
                minLines: isCompact ? 1 : 2,
                noAutoCorrect: true,
                noAutoCapitalize: true
            }), this.$el, 'prepend')

            // When the author allows the display of a word counter
            if (this.model.get('task_info_json').word_counter === true) {
                this.wordCounter = this.addChildView(new WordCounter(), '.js-word-counter-container')
                this.updateWordCounter()
            }
        }

        this.showCollaborativeGrading()
    },

    /**
     * If students have graded each other, show the grading under the task
     *
     * @returns {void}
     */
    showCollaborativeGrading() {

        if (!this.task_view.work_on) {
            return
        }

        const grading = this.task_view.work_on.model.get('StudentGradings')?.find(grading => grading.response_id === this.task_view.response.get('id'))

        if (!grading) {
            return
        }

        this.task_view.appendCollaborativeGrading(
            GradingTemplate({
                Styles,
                feedback: grading.reason,
            })
        )

        this.task_view.addChildView(
            new StarRater({
                amount: 3,
                size: 'medium',
                starred: Math.round(parseFloat(grading.score) * 3),
                isEditable: false,
                identifier: grading.response_id,
            }),
            '.js-star-rater'
        )

    },

    /**
     * updateWordCounter
     *
     * This function checks if there is a wordCounter active. If so, update the count with the user input.
     * Dependent on the plain textarea or the WYSIWYG editor we retrieve the answers in different ways.
     *
     */
    updateWordCounter() {
        if (this.wordCounter) {
            if (this.advancedInput === undefined) {
                this.wordCounter.updateWordCount(this.$('textarea').val());
            } else if (this.advancedInput.editor) {
                // We have to get the editor itself from WYSIWYG as we bypass the getContent() function
                this.wordCounter.updateWordCount(this.advancedInput.editor.getContent({format: 'text'}));
            }
        }
    },

    /**
     * onTextareaUpdate
     *
     * This function is triggered when the user has committed changes to the text area.
     * For the advanced editor, the edits can also be done by the mouse.
     * It will set a timeout that upon expiry will trigger the save method of the template.
     *
     */
    onTextareaUpdate() {
        this.updateWordCounter()

        clearTimeout(this.pendingSaveTimeout);
        this.pendingSaveTimeout = setTimeout(this.textareaChanged, 2000);
    },

    textareaChanged() {

        clearTimeout(this.pendingSaveTimeout);

        if (this.isDestroying === true) {
            return
        }

        // When the user is in a presentation, the answer is saved manually
        if (this.isPresentation) {
            return
        }

        if (this.advancedInput === undefined) {
            this.task_view.saveResponse(this.$('textarea').val());

            if (this.$('textarea').val() === undefined) {
                window.sentry.withScope(scope => {
                    scope.setExtra('pendingSaveTimeout', this.pendingSaveTimeout);
                    scope.setExtra('model', this.model);
                    scope.setExtra('el', this.el);
                    scope.setExtra('$el', this.$el)
                    scope.setExtra('advancedInput', this.advancedInput)

                    window.sentry.captureException('Template 2 save response with missing payload json_answer');
                });
            }
        } else {
            this.task_view.saveResponse(this.advancedInput.getContent());
        }
    },

    textareaWrapperClick() {

        if (
            // Check if the response needs to be scored by the student
            this.task_view.response.get('need-scoring') &&

            // Check if activity type is not presentation
            !this.isPresentation &&

            // Check if activity is not exam
            this.task_view.activityType !== 'exam' &&
            this.task_view.activityType !== 'generated_exam'
        ) {

            // If input is locked, show status
            this.task_view.openScoreFirstMessage();
        }
    },

    lockAnswer() {
        if (this.advancedInput === undefined) {
            this.$('textarea').attr('disabled', true)
        } else {
            this.advancedInput.disableEditor()
        }

        // If there is a send button, this means the answer could
        // have been edited after sending/saving it.
        if (this.task_view.sendButton) {

            // Therefore, put back the last saved instance of the answer in the input.
            this.$('textarea').val(this.savedResponse);
        }

    },

    unlockAnswer() {
        if (this.advancedInput === undefined) {
            // Unlock the textarea for the dom
            this.$('textarea').attr('disabled', false);
        } else if (this.advancedInput.editor) {
            this.advancedInput.enableEditor()
        }

        this.task_view.response.set('correction', true);
    },

    showAnswer() {

        // If a student clicks the show answer button while the timeout is active,
        // save the response first and then show the answer
        if (this.pendingSaveTimeout) {
            this.textareaChanged();
        }

        if (this.model.get('grading_mode') === 'auto') {
            this.listenTo(
                this.response,
                'change:score',
                this.checkAnswer
            );
            // check if given response if correct
            this.checkAnswer(true)

            if (!this.isCorrectAnswer()) {
                this.showModelAnswer()
            }
        } else {
            this.showModelAnswer()
        }
    },

    showAuthorAnswer() {
        this.$el.append(AuthorPreviewTemplate({
            Styles,
            content: this.model.get('task_info_json').answer,
        }))
        renderSpecialElements({}, this)
    },

    showModelAnswer() {
        let closestModelAnswer = this.model.get('task_info_json').answer

        if (
            !this.model.get('task_info_json').formatted &&
            this.model.get('grading_mode') === 'auto' &&
            APPLICATION !== 'author'
        ) {
            this.answerDiffView = this.addChildView(new AnswerDiff({
                givenAnswer: this.$('textarea').val(),
                modelAnswer: this.model.get('task_info_json').answer,
                modelAnswerVariants: this.model.get('task_info_json').variants,
                ignoreCase: !this.model.get('task_info_json')['case-sensitive'],
                answerInputElement: this.$('.js-textarea-wrapper')
            }), '.js-answer-diff')
            // If there are multiple model answer variants, use the model answer which is closest to the given answer
            // to display as the model answer as determined by the answerDiffView.
            if (this.answerDiffView.closestModelAnswer) {
                closestModelAnswer = this.answerDiffView.closestModelAnswer
            }
        }

        this.modelAnswerView = this.addChildView(
            this.getModelAnswerView(closestModelAnswer, true),
            '.js-correct-answer-container'
        )
    },

    getModelAnswerView(closestModelAnswer, doNotAnimate = false) {
        return new AnswerExplanation({
            isModelAnswer: true,
            title: window.i18n.gettext('Correct answer'),
            explanation: closestModelAnswer,
            icon: 'lightbulb',
            doNotAnimate,
        })
    },

    hideAnswer() {
        if (this.answerDiffView) {
            this.answerDiffView.destroy()
        }
        if (this.modelAnswerView) {
            this.modelAnswerView.hide();
        }

        this.removeResponseAccuracyStyling()

        this.stopListening(
            this.response,
            'change:score',
            this.checkAnswer
        );
    },

    getCorrectAnswer() {
        // Render the special elements for corrent answer.
        _.defer(renderSpecialElements, {}, this.task_view, this.task_view.$('.js-correct-answer-holder'));
        return this.model.get('task_info_json').answer;
    },

    getStudentAnswer(responseModel) {

        if (typeof responseModel.get('json_answer') === 'string') {

            let str = responseModel.get('json_answer').trim()

            const wordCounter = this.model.get('task_info_json').word_counter === true && new WordCounter()

            if (!this.model.get('task_info_json').formatted && !this.model.get('task_info_json').equationTools) {
                str = Util.nl2br(_.escape(str))
                str = Util.makeLinksClickable(str)
                if (wordCounter) {
                    wordCounter.updateWordCount(str)
                    str += wordCounter.el.outerHTML
                }
                return str
            }

            str = Util.renderContentSafely(responseModel.get('json_answer') || '')

            // Validate answer as empty when it does not contain a formula and has zero length after removing
            // all HTML tags and trimming it. This special case for formula has been added due to the way answers
            // containing only a formula are IMG or SVG tags without any textContent, making it seem to
            // Util.stripTags that the answer is empty whilst it is not.
            const isEmptyString = !/data\-base64formula=/.test(str) && Util.stripTags(str).trim().length === 0

            // Wrap student answer in a div so that we can apply styling
            if (!isEmptyString) {
                str = Template2AnswerTemplate({
                    Styles,
                    answer: str,
                })
            }

            if (!isEmptyString && wordCounter) {
                wordCounter.updateWordCount(Util.stripTags(str))
                str += wordCounter.el.outerHTML
            }

            // return null to show that the answer was given, but it's empty
            return isEmptyString ? null : str;
        }
    },

    /**
     * onClickSendPresentationAnswer
     *
     * Triggered when user sends an answer inside a presentation through the button.
     * It triggers an event in the presentation activity view.
     *
     */
    sendPresentationAnswer() {

        // A student may edit the answer before sending it again to the presentation.
        // When the teacher is checking the answers, the student must see
        // his saved answer on the screen. Therefore save student response in global
        // variable, to put it back in the input when checking answers
        this.savedResponse = this.$('textarea').val().trim();

        // If the saved response is not empty
        if (this.savedResponse) {

            // Save it to DB
            this.task_view.saveResponse(this.savedResponse);

            // Send a trigger to the presentation activity so we can show
            // the teacher an answer has been saved
            this.task_view.work_on.trigger('student-saved-presentation-answer');

            // Lock the answer for student
            this.lockAnswer();
        } else {

            // Show statusmessage saying student can't send an empty answer
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You can\'t send an empty answer'),
                'warning'
            );

            // Reset the view so student can fill an answer again
            this.task_view.onClickEditAnswer();
        }
    },
    /**
     * beforeDestroy
     *
     * Clear time out when this template view gets destroyed to prevent attempts to
     * get the contents of input fields that don't exist anymore after destruction.
     */
    beforeDestroy() {
        if (this.pendingSaveTimeout) {
            this.textareaChanged();
        }
        this.isDestroying = true;
    },

    /**
     * isCorrectAnswer - checks if student's given answer is equals to the model answer of one of it's variants
     *
     * @return {string}  correctAnswer shown to the student
     */
    isCorrectAnswer() {
        var response;

        // take the latest student's answer from the input
        var input = this.getInputValue();

        if (input && input.length > 0) {
            response = Util.normaliseCharacters(input, { removeNewLines: true });
        }

        let correctVariants = []
        if (this.model.get('task_info_json').variants) {
            correctVariants = _.pluck(this.model.get('task_info_json').variants, 'text').map(
                item => Util.normaliseCharacters(item, { removeNewLines: true })
            )
        } else {
            correctVariants = [ Util.stripTags(Util.normaliseCharacters(this.model.get('task_info_json').answer, { removeNewLines: true })) ]
        }

        if (!this.model.get('task_info_json')['case-sensitive']) {

            // check if answer have variants
            if (_.size(correctVariants) > 0) {

                correctVariants = _.map(correctVariants, (variant) => {
                    return variant.toLowerCase();
                });
            }

            if (response !== undefined) {
                response = response.toLowerCase();
            }
        }
        // if response is in the variants change the Modelanswer to the correct
        if (correctVariants.indexOf(response) > -1) {
            return true
        }

        return false;
    },

    /**
     * getInputValue
     *
     * get the content form the answer input field and strip tags from it
     *
     * @return {string}  from input content to check if it's correct
     */
    getInputValue() {
        if (this.advancedInput === undefined) {
            return this.$('textarea').val()
        }
        if (this.advancedInput) {
            return Util.stripTags(this.advancedInput.getContent())
        }
    },

    addResponseAccuracyStyling(isCorrect) {

        this.removeResponseAccuracyStyling()
        if (isCorrect) {
            this.$('.js-textarea-wrapper').addClass(Styles['response--correct']);
        } else {
            this.$('.js-textarea-wrapper').addClass(Styles['response--incorrect']);
        }
    },

    removeResponseAccuracyStyling() {
        this.$('.js-textarea-wrapper').removeClass(
            Styles['response--correct']
        ).removeClass(
            Styles['response--incorrect']
        )
    },

    checkAnswer(clientCheck) {

        /**
         * skip checking answer with auto grading on if teacher
         * has manually graded
         */
        if (this.response.get('scored_by') === 'teacher') {
            return
        }

        // check answer if there is no response or want to update before backend response
        if (this.response.get('score') === -1 || clientCheck === true) {
            const isCorrectAnswer = this.isCorrectAnswer()

            this.response.set('score', isCorrectAnswer ? 1 : 0)
            this.addResponseAccuracyStyling(isCorrectAnswer)
        } else {
            this.addResponseAccuracyStyling(this.response.get('score'))
        }
    },

    /**
     * render a "place in portfolio" button if this option has been turned.
     * the button will open a modal to place the open question in a
     * student's portfolio
     *
     * @returns {void}
     */
    renderPortfolioPlacer() {

        if (!this.model.get('task_info_json').canBePlacedInPortfolio) {
            return
        }

        this.portfolioPlacerView = this.addChildView(
            new CopyToPortfolioView({
                model: this.model,
                saveSource: async (portfolioProject) => {

                    // Create 3 paragraphs: activity name, question, given answer
                    let text = '<p>' + Backbone.Model.user.currentOpenActivity.get('name') + '</p>'
                    text += this.model.get('introduction')
                    if (this.advancedInput) {
                        text += this.advancedInput.getContent()
                    } else {
                        text += '<p>' + Util.stripTags(this.$('textarea').val()) + '</p>'
                    }

                    await portfolioProject.get('elements').create({
                        project_id: portfolioProject.get('id'),
                        template_id: 12,
                        element_type: 'source',
                        sequence: portfolioProject.get('elements').length,
                        title: '',
                        source: {
                            text,
                        },
                    })

                    Backbone.View.Components.modal.close()

                    Backbone.View.layout.openStatus(
                        window.i18n.sprintf(
                            window.i18n.gettext('Task copied to portfolio "%s"'),
                            portfolioProject.get('title')
                        ),
                        'success',
                        {
                            hasArrowIcon: true,
                            elementCallback: () => {
                                Backbone.history.navigate(
                                    `/portfolio/project/${portfolioProject.id}`,
                                    { trigger: true }
                                )
                            }
                        }
                    )
                }
            }),
            '.js-add-to-portfolio-holder'
        )
    }
});
